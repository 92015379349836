import React from 'react';

class SendButton extends React.Component {

	render() {
		if (this.props.sending) {
			return <button disabled className={this.props.className}>&middot;&middot;&middot;</button>;
		} else {
			return <button className={this.props.className} onClick={this.props.onClick}>{this.props.children}</button>;
		}
	}
}

export default SendButton;