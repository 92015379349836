import React, {useState} from 'react';
import { HappyIcon, SadIcon } from './Icons';
import './AskAccidentPrompt.scss';

const AskAccidentPrompt = (props) => {

	const [sending, setSending] = useState(false);

	const handleCancel = (e) => {
		e && e.preventDefault();
		props.dismissFunc();
	}

	const handleSuccess = () => {
		setSending(true);
		props.successFunc()
		.then( (r) => {
			handleCancel();
		})
		.finally( () => setSending(false) );
	}

	const handleAccident = () => {
		setSending(true);
		props.accidentFunc()
		.then( (r) => {
			handleCancel();
		})
		.finally( () => setSending(false) );
	}

	if (props.show) {
		return (
			<div className={`AskAccidentPrompt ${ sending ? 'AskAccidentPromt--sending' : ''}`}>
				<div className="AskAccidentPrompt__modal">
					<button 
						className="success-btn" 
						onClick={handleSuccess}
						disabled={sending}
					>Success! <HappyIcon /></button>
					<button 
						className="accident-btn" 
						onClick={handleAccident}
						disabled={sending}
					>Accident <SadIcon /></button>
					<button className="cancel link-button" onClick={handleCancel}>Cancel</button>
				</div>
			</div>
		);
	} else {
		return null;
	}
}

export default AskAccidentPrompt;