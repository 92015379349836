import React from 'react';
import './DateTimePicker.scss';

const leadingZero = (n) => (n > 9) ? `${n}` : `0${n}`;

function generateRange(min, max, step){
	let arr = [];
	for(let i = min; i <= max; i += step){
	   arr.push(i);
	}
	
	return arr;
  }

const makeOptions = (valuesArray, keyPrefix) => {
	return valuesArray.map( (v) => {
		let val = v;
		let label = v;
		let key = v;
		if (v.value) {
			val = v.value;
			key = v.value;
		}
		if (v.label) {
			label = v.label;
		}
		if (v.key) {

		}
		return <option key={`keyPrefix_${key}`} value={val}>{label}</option>
	});
} 

const DateTimePicker = ({handleChange, momentObj}) => {

	const date = new Date();
	const currentYear = date.getFullYear();
	const yearChoices = generateRange(currentYear-10, currentYear, 1);
	const monthChoices = [
		{value: 0, label: 'Jan'},
		{value: 1, label: 'Feb'},
		{value: 2, label: 'Mar'},
		{value: 3, label: 'Apr'},
		{value: 4, label: 'May'},
		{value: 5, label: 'Jun'},
		{value: 6, label: 'Jul'},
		{value: 7, label: 'Aug'},
		{value: 8, label: 'Sep'},
		{value: 9, label: 'Oct'},
		{value: 10, label: 'Nov'},
		{value: 11, label: 'Dec'}
	];
	const hourChoices = [...Array(24).keys()];
	const minuteChoices = [...Array(60).keys()];
	const dayChoices = () => {
		let dayCount;
		// remember that momentJS months are 0-index, so gotta add 1
		switch (momentObj.month()+1) {
			case 1:
			case 3:
			case 5:
			case 7:
			case 8:
			case 10:
			case 12:
				dayCount = 31;
				break;
			case 4:
			case 6:
			case 9:
			case 11:
				dayCount = 30;
				break;
			case 2:
				if (((momentObj.year() % 4 === 0) && !(momentObj.year() % 100 === 0))
					|| (momentObj.year() % 400 === 0)) {
					dayCount = 29;
					} else {
					dayCount = 28;
					}
				break;
			default:
				dayCount = -9999; // invalid month
		}
		// momentJS days are NOT index-0, so start with 1
		return generateRange(1, dayCount, 1);
	}

	const _onChange = () => {
		// // build moment from local times
		// // @TODO this is dumb. Instead of useState, should just be modifying a single moment object and then returning it
		// const timestamp = `${year}-${month}-${day}T${hour}:${minute}:00`;
		console.log(momentObj);
		handleChange && handleChange(momentObj);
	}

	return (
		<div className="DateTimePicker">
			<label>Date</label>
			<div className="DateTimePicker__date">
				<select name="day" defaultValue={`${momentObj.date()}`} onChange={(e) => {momentObj.date(e.target.value); _onChange();}}>
					{makeOptions(dayChoices(), 'day')}
				</select>
				&nbsp;
				<select name="month" defaultValue={momentObj.month()} onChange={(e) => {momentObj.month(e.target.value); _onChange();}}>
					{makeOptions(monthChoices, 'month')}
				</select>
				&nbsp;
				<select name="year" defaultValue={momentObj.year()} onChange={(e) => {momentObj.year(e.target.value);  _onChange();}}>
					{makeOptions(yearChoices, 'year')}
				</select>
			</div>
			<label>Time</label>
			<div className="DateTimePicker__time">
				<div>
					{/* <label>Hour</label> */}
					<select name="hour" defaultValue={momentObj.hours()} onChange={ (e) => {momentObj.hours(e.target.value);  _onChange();}}>
					{ hourChoices.map( (h) => <option key={h} value={h}>{leadingZero(h)}</option> )}
					</select>
				</div>
				<div>&nbsp;:&nbsp;</div>
				<div>
					{/* <label>Minutes</label> */}
					<select name="minute" defaultValue={momentObj.minutes()} onChange={(e) => {momentObj.minutes(e.target.value);  _onChange();}}>
					{ minuteChoices.map( (m) => <option key={m} value={m}>{leadingZero(m)}</option> ) }
					</select>
				</div>
			</div>
		</div>
	);
}

export default DateTimePicker;