import React, {useState} from 'react';
import moment from 'moment';

// import config from '../config';
// import easypuppyApi from '../api/easypuppy';

import HistoryIcon from './HistoryIcon';
import ActivityEditButton from './ActivityEditButton';
import {NoteIcon} from './Icons.js';

// const deleteActivity = (activity, deleteCallback) => {
// 	easypuppyApi.delete(config.activityDeletePath(activity))
// 	.then( res => deleteCallback() ) // update parent state @TODO This should be redux or context API
// 	.catch( err => console.log(err.response) );
// }

const activityDuration = (activity) => {
	const dur = moment.duration(moment(activity.ended_at).diff( moment(activity.started_at)));
	return (dur.as('minutes') < 60) ? `${Math.round(dur.as('minutes'))} mins` : `${dur.as('hours').toPrecision(2)} hrs` ;
}

const History = ({history, clockTimeMode}) => {
	let className = 'history__row';
	className = (history.is_accident) ? className + ' accident' : className;

	const isActivity = (history.klass === 'Sleep' || history.klass === 'Exercise');
	const listTime = isActivity ? history.started_at : history.created_at;

	const displayTiming = (timestamp) => {
		const momentTime = moment(listTime);
		if (clockTimeMode) {
			return momentTime.format('HH:mm MMM D');
		} else {
			return momentTime.fromNow();
		}
	}

	return <li className={className}>
		<HistoryIcon type={history.klass} />
		&nbsp;
        <time className="history__time">{ displayTiming(listTime) }</time>
        { isActivity && history.ended_at && <span className="history__duration">({activityDuration(history)})</span>}
        <div className="history__dots"></div>
        {/*<button className="secondary button tiny history__delete-btn" onClick={ (e) => deleteActivity(history, deleteCallback) }>
        	&times;
        </button>*/}
        { history.note && <NoteIcon />}
        <ActivityEditButton activity={history} />
	</li>;
}

const HistoryList = ({histories, showTimeModeControl, startClockTimeMode} ) => {
	histories = (histories) ? histories : [];
	const [clockTimeMode, setClockTimeMode] = useState(startClockTimeMode ?? false);

	return (
	<>
	{ showTimeModeControl && <div className="history-time-mode-control" style={{textAlign: 'center'}}>
		<button style={{marginBottom: 0}} className={`button tiny ${!clockTimeMode ? "hollow" : ''}`} onClick={ (e) => setClockTimeMode(true)}>time / date</button>
		{' '}
		<button style={{marginBottom: 0}} className={`button tiny ${clockTimeMode ? "hollow" : ''}`} onClick={ (e) => setClockTimeMode(false)}>&hellip;ago</button>
	</div> }
	<ul className="history">
		{ histories.map( (history) => <History key={`${history.klass}${history.id}`} history={history} clockTimeMode={clockTimeMode} /> ) }
    </ul>
	</>);

}

export default HistoryList;