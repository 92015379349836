import React from 'react';
import Avatar from './Avatar';
import { connect } from 'react-redux';
import easypuppyApi from '../api/easypuppy';
import config from '../config';
import { fetchCurrentPersonAction } from '../actions';
import { Link } from "react-router-dom";
import SendButtonText from './SendButtonText';
import DeletePuppyButton from './DeletePuppyButton';

const EditErrors = ({errors}) => {
	return (
		<div class="error_explanation">
    		<strong>{ errors.length } error(s) prevented this update:</strong>
 			<ul>
    			{ errors && errors.full_messages.map( (msg) => <li>{ msg }</li> ) }
    		</ul>
  		</div>
	);
}


class EditPuppy extends React.Component {

	state = {
		errors: false,
		newPuppyFields: {},
		sending: false
	}

	constructor(props) {
		super(props);
		this.state.puppy = props.person.puppies[props.match.params.index];
		this.form = React.createRef();
		this.fileInput = React.createRef();
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let newPuppyFields = this.state.newPuppyFields;
		newPuppyFields[name] = value;
		this.setState({newPuppyFields: newPuppyFields});
	}

	buildFormDataObj = (event) => {
		const formData = new FormData();
		Object.entries(this.state.newPuppyFields).map( (entry) => formData.set(`puppy[${entry[0]}]`, entry[1]) )
		if (this.fileInput.current.files.length) {
			formData.append('puppy[headshot]',this.fileInput.current.files[0]);
		}
		return formData;
	}

    handleSubmit (event, formData) {

        event.preventDefault();

        if (![...formData].length) {
        	return;
        }

        this.setState({sending: true, errors: false});

        easypuppyApi.patch( 
            config.puppyEditPath(this.props.person.puppies[this.props.match.params.index].id),
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then( res => {

        	if (res.data.errors) {
        		this.setState({errors: res.data.errors, sending: false});
        		console.log('res.data.errors', res);
        		return;
        	}

        	let newPuppy = res.data;
            this.setState(
                {
                    puppy: newPuppy,
                    sending: false
                }
            );
            //@TODO This smells stinky. Think about this more
            this.props.dispatch(fetchCurrentPersonAction());
        })
        .catch( err => {
        	console.log('catch', err);
        });
        
    }

	render() {
		return (

			<div data-testid="EditPuppy">
			{ this.state.errors && <EditErrors errors={this.state.errors} />}

			<form ref={this.form} onSubmit={ (e) => {this.handleSubmit(e, this.buildFormDataObj()) } }>
			    <label>Name</label>
			    <h1>
			        <input type="text" name="name" defaultValue={this.state.puppy.name} onChange={this.handleInputChange} />
			    </h1>
			    
			    <Avatar puppy={this.state.puppy} />
			    			    
			    <label>Photo</label>
			    <input type='file' name="headshot" ref={this.fileInput} />

			    <label>Birthday</label>
			    <input type="date" name="dob" defaultValue={this.state.puppy.dob} onChange={this.handleInputChange} />
			    
			    <button type="submit" className="button expanded" disabled={this.state.sending}><SendButtonText text="Update" sending={this.state.sending} /></button>
			</form>

			<div style={{display:'flex',justifyContent: 'space-between'}}>
				<Link className="button secondary" to={`/puppy/${this.props.match.params.index}`}>Done</Link>
				<DeletePuppyButton puppy={this.state.puppy} />
			</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
  return {
    person: state.person,
    loading: state.loading,
    auth: state.auth
  };
}

export default connect(mapStateToProps)(EditPuppy);
