const BASE = process.env.REACT_APP_WAGARUFFIN_API_URI;
const PATH = '/api/v1';
const BASEPATH = BASE + PATH;

const config = {
	
	basePath : BASEPATH,
	puppyEditPath : (id) => `${BASEPATH}/puppies/edit/${id}.json`,
	puppyDeletePath: (id) => `${BASEPATH}/puppies/destroy/${id}.json`,
	puppyCreatePath : `${BASEPATH}/puppies/create.json`,
	currentPersonPath : `${BASEPATH}/person.json`,
	loginPath : `${BASEPATH}/auth/sign_in.json`,
	signUpPath : `${BASEPATH}/auth.json`,
	logoutPath : `${BASEPATH}/auth/sign_out.json`,
	puppyPeePath : `${BASEPATH}/puppies/pee.json`,
	puppyPooPath : `${BASEPATH}/puppies/poo.json`,
	puppyEatPath : `${BASEPATH}/puppies/eat.json`,
	puppyDrinkPath : `${BASEPATH}/puppies/drink.json`,
	helpUrl : `mailto:woof@wagaruffin.com?subject=Help with Wagaruffin`,
	personEditPath : `${BASEPATH}/settings.json`,
	passwordResetPath : `${BASEPATH}/auth/password`,
	activityDeletePath : (activity) => `${BASEPATH}/${activity.klass.toLowerCase()}/delete/${activity.id}.json`,
	invitationAcceptPath : (code) => `${BASEPATH}/invitations/accept/${code}`,
	createInvitationPath : `${BASEPATH}/invitations.json`,
	updatePeePath : (id) => `${BASEPATH}/pee/update/${id}.json`,
	updatePooPath : (id) => `${BASEPATH}/poo/update/${id}.json`,
	updateEatPath : (id) => `${BASEPATH}/eat/update/${id}.json`,
	updateDrinkPath : (id) => `${BASEPATH}/drink/update/${id}.json`,
	startActivityPath : `${BASEPATH}/activity/start.json`,
	updateActivityPath : `${BASEPATH}/activity/update.json`,
	deleteActivityPath : (id) => `${BASEPATH}/activity/delete/${id}.json`,
	puppyHistoryPath : (id) => `${BASEPATH}/puppies/history/${id}.json`,
	companionPath : (puppy_id) => `${BASEPATH}/companions/${puppy_id}.json`
}

export default config;