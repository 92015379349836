import React from 'react';
import easypuppyApi from '../api/easypuppy';
import config from '../config';
import {Redirect} from "react-router-dom";
import InlineError from './InlineError';
import SendButtonText from './SendButtonText';

class Settings extends React.Component {

	constructor(props) {
		super(props);

		this.state = {};
		this.state.errors = false;
		this.state.person = this.props.person;
		// These don't come back in the API response
		this.state.person.password = '';
		this.state.person.password_confirmation = '';
		this.state.sending = false;
		this.form = React.createRef();

		this.state.newPersonFields = {}

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidUpdate(prevProps) {
		if (this.props.person !== prevProps.person) {
			this.setState({person: this.props.person});
		}
	}

    handleSubmit (event, formData) {

        event.preventDefault();

        this.setState({sending: true, errors: false});

        easypuppyApi.patch( 
            config.personEditPath,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        )
        .then( res => {

        	if (res.data.errors) {
        		this.setState({errors: res.data.errors, sending: false});
        		console.log('res.data.errors', res);
        		return;
        	}

            this.props.upstreamStateFunc({person: res.data});
            let newPerson = res.data;
            newPerson.password = '';
            newPerson.password_confirmation = '';
            this.setState(
                {
                    person: newPerson,
                    sending: false
                }
            );
        })
        .catch( err => {
        	console.log("catch", err);
          this.setState({ sending: false });
        });
        
    }

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		let newPersonFields = this.state.newPersonFields;
		newPersonFields[name] = value;
		this.setState({newPersonFields: newPersonFields});
	}

	buildFormDataObj = (event) => {
		const formData = new FormData();
		Object.entries(this.state.newPersonFields).map( (entry) => formData.set(`person[${entry[0]}]`, entry[1]) )
		return formData;
	}

	render() {
		if (!this.state.person) {
			return <Redirect to="/signin" />;
		}
		return (

			<React.Fragment>

				<h1>Settings</h1>

				<form ref={this.form} onSubmit={ (e) => {this.handleSubmit(e, this.buildFormDataObj()) } }>

					<div className="field">
					<label>First name <InlineError name="first_name" errors={this.state.errors} /></label>
					<input type="text" name="first_name" defaultValue={this.state.person.first_name} onChange={this.handleInputChange} />
					</div>

					<div className="field">
					<label>Last name <InlineError name="last_name" errors={this.state.errors} /></label>
					<input type="text" name="last_name" defaultValue={this.state.person.last_name} onChange={this.handleInputChange} />
					</div>

					<div className="field">
					<label>Email <InlineError name="email" errors={this.state.errors} /></label>
					<input type="text" name="email" defaultValue={this.state.person.email} onChange={this.handleInputChange} />
					</div>

					{/* <div className="field">
					<label>US phone number # <InlineError name="phone_number" errors={this.state.errors} /></label>
					<input type="text" name="phone_number" defaultValue={this.state.person.phone_number} onChange={this.handleInputChange} placeholder="555 555 5555"/>
					</div>

					<div className="field checkbox-w-label">
					  <input type="checkbox" name="text_me" id="text_me" defaultChecked={ this.state.person.text_me ? true : false } onChange={this.handleInputChange} />
					  <label htmlFor="text_me">Text me when my puppy needs to go out</label>
					</div> */}

					<div className="field">
					<label>New password <InlineError name="password" errors={this.state.errors} /></label>
					<input type="password" name="password" onChange={this.handleInputChange} />
					</div>

					<div className="field">
					<label>New password again <InlineError name="password_confirmation" errors={this.state.errors} /></label>
					<input type="password" name="password_confirmation" onChange={this.handleInputChange} />
					</div>

					<button type="submit" className="button expanded" disabled={this.state.sending}><SendButtonText text="Save settings" sending={this.state.sending} /></button>


				</form>
			</React.Fragment>
		);
	}
}

export default Settings;