import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './components/App';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import store from './store';

const renderReactDom = () => {

	ReactDOM.render(
		<Provider store={store}>
			<App />
		</Provider>, 
		document.getElementById('root')
	);

};

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    renderReactDom();
  }, false);
} else {
  renderReactDom();
}

if (module.hot) {
  module.hot.accept();
}
