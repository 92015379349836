import axios from 'axios';
import store from '../store';
import config from '../config';

const api = axios.create({
	// baseURL: '//0.0.0.0:3000',
	withCredentials: false,
	headers: {
		'Accept': 'application/json',
		'Cache-Control': 'no-cache'
    }
});

const addAuthCredentials = () => {
	const auth = store.getState().auth;
	if (auth) {
		api.defaults.headers.common['access-token'] = auth['token'];
	    api.defaults.headers.common['uid'] = auth['uid'];
	    api.defaults.headers.common['expiry'] = auth['expiry'];
	    api.defaults.headers.common['client'] = auth['client'];
	}
}

addAuthCredentials();

store.subscribe( addAuthCredentials );

api.deletePuppy = (id) =>  api.delete(config.puppyDeletePath(id));

api.acceptInvitation = (code) => api.post(config.invitationAcceptPath(code));

api.createInvitation = (params) => api.post(config.createInvitationPath, params);

api.updatePee = (params) => api.patch(config.updatePeePath(params.id), params);
api.updatePoo = (params) => api.patch(config.updatePooPath(params.id), params);
api.updateEat = (params) => api.patch(config.updateEatPath(params.id), params);
api.updateDrink = (params) => api.patch(config.updateDrinkPath(params.id), params);

api.deleteAction = (action) => api.delete(config.activityDeletePath(action));

api.startActivity = (params) => api.post(config.startActivityPath, params);
api.updateActivity = (params) => api.patch(config.updateActivityPath, params);
api.deleteActivity = (params) => api.delete(config.deleteActivityPath(params.id));

api.getPuppyHistory = (id) => api.get(config.puppyHistoryPath(id));

api.updateNotify = (puppy_id, params) => api.patch(config.companionPath(puppy_id), params)

export default api;