import React, {useState} from 'react';
import { PeeIcon, PooIcon, FoodIcon, WaterIcon, SleepIcon, BallIcon } from './Icons';
import moment from 'moment';
import './ActivityEditMode.scss';
import api from '../api/easypuppy.js';
import { receiveUpdatedExcretionOrIntake, deleteAction, receiveUpdatedActivity } from '../actions.js';
import { connect } from 'react-redux';
import AsyncButton from './AsyncButton.js';
import DateTimePicker from './DateTimePicker.js';

const EditMode = (props) => {

	const handleClose = (e) => {
		e && e.preventDefault();
		props.closeFunc();
	}

	const klassMap = {
		'Pee' : {
			name: 'Pee',
			icon: <PeeIcon />,
			updateFunc: api.updatePee,
			deleteFunc: api.deleteAction,
			afterUpdateFunc: props.receiveUpdatedExcretionOrIntake
		},
		'Poo' : {
			name: 'Poo',
			icon: <PooIcon />,
			updateFunc: api.updatePoo,
			deleteFunc: api.deleteAction,
			afterUpdateFunc: props.receiveUpdatedExcretionOrIntake
		},
		'Eat' : {
			name: 'Eat',
			icon: <FoodIcon />,
			updateFunc: api.updateEat,
			deleteFunc: api.deleteAction,
			afterUpdateFunc: props.receiveUpdatedExcretionOrIntake
		},
		'Drink' : {
			name: 'Drink',
			icon: <WaterIcon />,
			updateFunc: api.updateDrink,
			deleteFunc: api.deleteAction,
			afterUpdateFunc: props.receiveUpdatedExcretionOrIntake
		},
		// Legacy model names
		'Food' : {
			name: 'Eat',
			icon: <FoodIcon />,
			updateFunc: api.updateEat,
			deleteFunc: api.deleteAction,
			afterUpdateFunc: props.receiveUpdatedExcretionOrIntake
		},
		'Water' : {
			name: 'Drink',
			icon: <WaterIcon />,
			updateFunc: api.updateDrink,
			deleteFunc: api.deleteAction,
			afterUpdateFunc: props.receiveUpdatedExcretionOrIntake
		},
		'Sleep' : {
			name: 'Sleep',
			icon: <SleepIcon />,
			updateFunc: api.updateActivity,
			deleteFunc: api.deleteActivity,
			afterUpdateFunc: props.receiveUpdatedActivity
		},
		'Exercise' : {
			name: 'Exercise',
			icon: <BallIcon />,
			updateFunc: api.updateActivity,
			deleteFunc: api.deleteActivity,
			afterUpdateFunc: props.receiveUpdatedActivity
		}

	}

	const name = klassMap[props.activity.klass].name;
	const icon = klassMap[props.activity.klass].icon;
	const updateFunc = klassMap[props.activity.klass].updateFunc;
	const deleteFunc = klassMap[props.activity.klass].deleteFunc;
	const afterUpdate = klassMap[props.activity.klass].afterUpdateFunc;
	const showAccident = (props.activity.klass === 'Pee' || props.activity.klass === 'Poo');
	const isDuration = (props.activity.klass === 'Sleep' || props.activity.klass === 'Exercise');

	const [createdAt, setCreatedAt] = useState(moment(props.activity.created_at));
	const [startedAt, setStartedAt] = useState(moment(props.activity.started_at));
	const [endedAt, setEndedAt] = useState(props.activity.ended_at ? moment(props.activity.ended_at) : null);
	const [isAccident, setIsAccident] = useState(props.activity.is_accident || false);
	const [note, setNote] = useState(props.activity.note ||  null);
	const [fetching, setFetching] = useState(false);

	const handleNoteChange = (e) => {
		setNote(e.target.value);
	}

	const handleCreatedAtChange = (moment) => {
		console.log('changed createdAt', moment.format());
		setCreatedAt(moment);
	}

	const handleStartedAtChange = (moment) => {
		console.log('changed startedAt', moment.format());
		setStartedAt(moment);
	}

	const handleEndedAtChange = (moment) => {
		console.log('changed endedAt', moment.format());
		setEndedAt(moment);
	}

	const handleAccidentChange = (e) => {
		setIsAccident( e.target.checked ? true : false );
	}

	const handleUpdate = (e) => {
		setFetching(true);

		let payload = {
			created_at: createdAt,
			note: note,
			id: props.activity.id
		};
		if (isDuration) {
			payload.started_at = startedAt;
			payload.ended_at = endedAt;
		} else {
			payload.created_at = createdAt;
		}
		if (showAccident) {
			payload.is_accident = isAccident;
		}
		updateFunc(payload)
		.then( (response) => {
			afterUpdate(response.data);
		})
		.catch( (e) => console.error(e) )
		.finally( () => {setFetching(false)} );
	}

	const handleDelete = (e) => {
		if (window.confirm('Are you sure you want to delete this? Just checking...')) {
			deleteFunc(props.activity)
			.then( (res) => { 
				props.deleteAction(props.activity);
				// props.closeFunc(); //@TODO seems like the entire component gets unmounted automatically
			})
			.catch( err => console.error(err) );
		}
	}

	return(
		<section className="ActivityEditMode">
			<button onClick={handleClose} className="ActivityEditMode__close-btn">&times;</button>
			<h1 className="ActivityEditMode__title">
			{ icon } { name }			
			</h1>

			{ showAccident &&
			<label>
				<input 
					type="checkbox" 
					checked={isAccident} 
					onChange={handleAccidentChange} />
				Accident?
			</label>
			}

			{ !isDuration && <>
				<label>When</label>
				<DateTimePicker handleChange={handleCreatedAtChange} momentObj={createdAt} />
			</> }

			{ isDuration && <>

				<label>Started</label>
				<DateTimePicker handleChange={handleStartedAtChange} momentObj={startedAt} />

				<label className="u-pushup-2">Ended</label>
				{ endedAt && 
				<DateTimePicker handleChange={handleEndedAtChange} momentObj={endedAt} />
				}
				{/* This sucks but React throws warnings if value = null */}
				{ !endedAt && <i>In progress&hellip;</i>}

			</> }

			<div className="u-pushup-2">
				<label>Note</label>
				<textarea 
					rows="2" 
					placeholder="Some interesting details..."
					value={note || ''}
					onChange={handleNoteChange}>
				</textarea>
			</div>

			<div className="ActivityEditMode__buttons buttons">
				<AsyncButton label="Update" onClick={handleUpdate} working={fetching} cssClass="button expanded" />
				<button className="link-button danger error" onClick={handleDelete}>Delete</button>
			</div>

		</section>
	);
}

function mapStateToProps(state) {
  return {
    person: state.person,
    // loading: state.loading,
    // auth: state.auth
  };
}

export default connect(mapStateToProps, {receiveUpdatedExcretionOrIntake, deleteAction, receiveUpdatedActivity})(EditMode);