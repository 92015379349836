import React from 'react';
import config from '../config';
import easypuppyApi from '../api/easypuppy';
import {Link} from "react-router-dom";
import queryString from "query-string";
import store from '../store';
// import { connect } from 'react-redux';

class ResetPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitSuccess : false,
      sending : false,
      error: false
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({sending: true, error: false, submitSuccess: false});

    const parse = queryString.parse(this.props.location.search);
    const authHeaders = {
      'token': parse.token, //query string ALSO has access-token param that's identical
      'uid': parse.uid,
      'expiry': parse.expiry,
      'client': parse.client //query string ALSO has client_id param that's identical
    };

    store.dispatch({
      type: 'RECEIVE_AUTH',
      auth: authHeaders
    });

    easypuppyApi.patch(config.passwordResetPath, {
          password: document.getElementById("password").value,
          password_confirmation: document.getElementById("password_confirmation").value
    })
    .then( res => this.setState({sending: false, submitSuccess: true, error: false}))
    .catch(
      err => { 
        console.log(err);
        this.setState({sending: false, error: true}); 
      }
    )
  }

  render() {

    // Work like this is why you shouldn't code while really tired or busy...
    return (
        <div>
          <h2>Save a New Password</h2>
          { !this.state.submitSuccess && !this.state.error && <p>Enter your new password (twice).</p> }
          { this.state.submitSuccess && <p className="label success">Your password was reset.</p> }
          { this.state.error && <p className="label warning">Something went wrong :-(</p> }
          { !this.state.submitSuccess && <form onSubmit={this.handleSubmit}>
            <div className="field">
              <label>Password</label>
              <input id="password" type="password" />
            </div>

            <div className="field">
              <label>Password (again)</label>
              <input id="password_confirmation" type="password" />
            </div>

            <div className="actions">            
              <button className="button">{ this.state.sending && '...'} {!this.state.sending && 'Save'}</button>
            </div>
          </form> }
          { this.state.submitSuccess && <div><Link className="button" to="/signin">Sign in</Link></div>}
        </div>
      );
  };
};

// function mapStateToProps(state) {
//   return {
//     person: state.person,
//     loading: state.loading
//   };
// }

// export default connect(mapStateToProps)(ResetPassword);
export default ResetPassword;
