import React from 'react';
import easypuppyApi from '../api/easypuppy';
import {Link} from "react-router-dom";
// import store from "../store";
import SendButtonText from './SendButtonText';
// import queryString from "query-string";
import { connect } from 'react-redux';


class PuppyInvitation extends React.Component {

  state = {
    errors: false,
    sending: false,
    sendSuccess: false,
  }

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
  }

  componentDidMount() {
    this.setState({errors: false});
  }

  currentPuppy = () => {
      return this.props.person.puppies[this.props.match.params.index];
  }

  async handleSubmit (e) {
      e.preventDefault();

      this.setState({sending: true});

      easypuppyApi.createInvitation({
        invitee_email: document.getElementById("email").value,
        puppy_id: this.currentPuppy().id
      })
      .then( (res) => {
        this.setState({sendSuccess: true})
      })
      .catch( (err) => {
        this.setState({errors: err.response.data.errors});
      })
      .finally( () => {
        this.setState({sending: false});
      });
  }

  // @TODO This is lame!
  renderErrors(errors) {
    return <React.Fragment>{Object.keys(errors).map( (key, index) => <p key={index}> {errors[key]}</p> )}</React.Fragment>;
  };

  render() {

    return (
        <div data-testid="PuppyInvitation">
          <h2>Invite a Helper for {this.currentPuppy().name}</h2>

          { this.state.sendSuccess && <div style={{whiteSpace: "normal"}} className="label success">Yay! Your invitation was sent.</div> }

          { this.state.errors && <div style={{whiteSpace: "normal"}} className="label alert">{this.renderErrors(this.state.errors)}</div> }
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <label>Helper's Email</label>
              <input id="email" placeholder="email" type="email" />
            </div>

            <div className="actions">            
              <button className="button" disabled={this.state.sending}>
                <SendButtonText text="Send Invitation" sending={this.state.sending} />
              </button>
            </div>
          </form>

          <Link className="button secondary" to={`/puppy/${this.props.match.params.index}`}>Done</Link>
        </div>
      );
  };
};

function mapStateToProps(state) {
  return {
    person: state.person,
    loading: state.loading
  };
}

export default connect(mapStateToProps)(PuppyInvitation);
// export default PuppyInvitation;
