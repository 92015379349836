import React from 'react';
import './AsyncButton.scss';
// import { PeeIcon, PooIcon, FoodIcon, WaterIcon } from './Icons';

const AsyncButton = (props) => {

	const buttonText = () => {
		if (props.working) {
			return <>&middot;&middot;&middot;</>;
		} else {
			return props.label || 'Click';
		}
	}

	return (<button onClick={props.onClick} className={`AsyncButton ${props.cssClass || ''}`} disabled={props.working || false}>{buttonText()}</button>);
}

export default AsyncButton;