import React from "react";
import { PeeIcon, PooIcon } from "./Icons";
import ExcretionPrediction from "./ExcretionPrediction";
import HistoryList from "./HistoryList";
import {
  PeeButton,
  PooButton,
  EatButton,
  DrinkButton,
  SleepButton,
  StopSleepButton,
  ExerciseButton,
  StopExerciseButton,
} from "./ActivityButtons";
import api from "../api/easypuppy.js";
import Avatar from "./Avatar";
import config from "../config";
// import easypuppyApi from '../api/easypuppy';
import { Link, withRouter, Redirect } from "react-router-dom";
import "./Puppy.scss";
import { connect } from "react-redux";
import { addNewHistory, receiveUpdatedActivity, fetchCurrentPersonAction } from "../actions.js";
import moment from "moment";
import NotifyMe from "./NotifyMe"
const easypuppyApi = api; // accidentally imported twice

class Puppy extends React.Component {
  constructor(props) {
    super(props);
    this.addActivity = this.addActivity.bind(this);
    this.addActivity = this.addActivity.bind(this);
    this.currentPuppy = this.currentPuppy.bind(this);
    this.wakeUp = this.wakeUp.bind(this);
    this.stopExercise = this.stopExercise.bind(this);
  }

  currentPuppy = () => {
    return this.props.person.puppies[this.props.match.params.index];
  };

  conditionalActivity = (puppy) => {
    if (puppy.history.length) {
      return (
        <React.Fragment>
          <h4 style={{ marginTop: "1.5em" }}>Activity</h4>
          <HistoryList histories={puppy.history} />
          <Link
            to={`/puppy/${this.props.match.params.index}/history`}
            className="button secondary small expanded"
          >
            View all activity
          </Link>
        </React.Fragment>
      );
    }
  };

  async addActivity(klass, options) {
    // console.log(`${klass}!`);
    const { isAccident = false, retroactive = 0 } = options;
    let url;
    switch (klass) {
      case "pee":
        url = config.puppyPeePath;
        // console.log(url);
        break;
      case "poo":
        url = config.puppyPooPath;
        break;
      case "food":
        url = config.puppyEatPath;
        break;
      case "water":
        url = config.puppyDrinkPath;
        break;
      default:
        console.log("Unrecognzied activity klass");
    }
    let response;
    try {
      response = await easypuppyApi.post(url, {
        puppy_id: this.currentPuppy().id,
        retroactive: retroactive,
        is_accident: isAccident,
      });

      let newPerson = this.props.person;
      newPerson.puppies[this.props.match.params.index] = response.data;
      this.props.upstreamStateFunc({ person: newPerson });
    } catch (e) {
      // Non-200 response
      if (e.response) {
        alert(
          `RESPONSE ERROR: ${e.message}. Please contact us if this error continues.`
        );
        // Request was made, but no response received.
      } else if (e.request) {
        alert(
          `REQUEST ERROR. Your device may be offline. Please contact us if this error continues.`
        );
        // Probably an error before the request was even sent
      } else {
        alert(
          `UNRECOGNIZED ERROR: ${e.message}. Please contact us if this error continues.`
        );
      }
      console.log(e);
      return Promise.reject();
    }

    return Promise.resolve(response);
  }

  parentOnlyOptions = () => {
    return (
      <React.Fragment>
        <p>
          <Link to={`/puppy/${this.props.match.params.index}/edit`}>
            Edit {this.currentPuppy().name}
          </Link>
        </p>
        <p>
          <Link to={`/puppy/${this.props.match.params.index}/invite`}>
            Invite a Helper
          </Link>
        </p>
      </React.Fragment>
    );
  };

  wakeUp = () => {
    let sleepActivity = Object.assign(
      {},
      this.currentPuppy()
        .history.filter((hx) => {
          return hx.klass === "Sleep" && !hx.ended_at;
        })
        .pop()
    );
    sleepActivity.ended_at = moment.utc().format();
    api.updateActivity(sleepActivity).then((res) => {
      this.props.receiveUpdatedActivity(res.data);
    });
  };

  stopExercise = () => {
    let exerciseActivity = Object.assign(
      {},
      this.currentPuppy()
        .history.filter((hx) => {
          return hx.klass === "Exercise" && !hx.ended_at;
        })
        .pop()
    );
    exerciseActivity.ended_at = moment.utc().format();
    api.updateActivity(exerciseActivity).then((res) => {
      this.props.receiveUpdatedActivity(res.data);
    });
  };

  render() {
    if (!this.props.person) {
      return "NO PUPPY!";
    }
    if (!this.props.person.puppies.length) {
      return <Redirect to="/newpuppy" />;
    }

    const puppy = this.currentPuppy()

    return (
      <div data-testid="Puppy">
        <div className="puppy-header">
          <h1>{this.currentPuppy().name}</h1>
          <Avatar puppy={this.currentPuppy()} />
        </div>

        <div>
          <div className="nexts">
            <div className="next next--pee">
              <div>
                Next <PeeIcon />
              </div>
              <ExcretionPrediction
                puppy={this.currentPuppy()}
                pee_or_poo="pee"
              />
            </div>

            <div className="next next--poo">
              <div>
                Next <PooIcon />
              </div>
              <ExcretionPrediction
                puppy={this.currentPuppy()}
                pee_or_poo="poo"
              />
            </div>
          </div>

          <div className="ActivityButtons">
            <PeeButton
              puppy={this.currentPuppy()}
              successFunc={() =>
                this.addActivity("pee", { isAccident: false, retroactive: 0 })
              }
              accidentFunc={() =>
                this.addActivity("pee", { isAccident: true, retroactive: 0 })
              }
            />
            <PooButton
              puppy={this.currentPuppy()}
              successFunc={() =>
                this.addActivity("poo", { isAccident: false, retroactive: 0 })
              }
              accidentFunc={() =>
                this.addActivity("poo", { isAccident: true, retroactive: 0 })
              }
            />
            <DrinkButton
              puppy={this.currentPuppy()}
              onClickFunc={() => {
                this.addActivity("water", {});
              }}
            />
            <EatButton
              puppy={this.currentPuppy()}
              onClickFunc={() => {
                this.addActivity("food", {});
              }}
            />
            {!this.currentPuppy().isSleeping && (
              <SleepButton
                onClickFunc={() => {
                  api
                    .startActivity({
                      puppy_id: this.currentPuppy().id,
                      type: "Sleep",
                    })
                    .then((response) => {
                      console.log("sleep response", response);
                      this.props.addNewHistory(response.data);
                    })
                    .then(() => {
                          // This is the lazy way to update the state
                          this.props.fetchCurrentPersonAction()
                    });
                }}
              />
            )}
            {this.currentPuppy().isSleeping && (
              <StopSleepButton onClickFunc={this.wakeUp} />
            )}
            {!this.currentPuppy().isExercising && (
              <ExerciseButton
                onClickFunc={() => {
                  api
                    .startActivity({
                      puppy_id: this.currentPuppy().id,
                      type: "Exercise",
                    })
                    .then((response) => {
                      console.log("exercise response", response);
                      this.props.addNewHistory(response.data);
                    })
                    .then(() => {
                      // This is the lazy way to update the state
                      this.props.fetchCurrentPersonAction()
                    });
                }}
              />
            )}
            {this.currentPuppy().isExercising && (
              <StopExerciseButton onClickFunc={this.stopExercise} />
            )}
          </div>

          {this.conditionalActivity(this.currentPuppy())}
        </div>
        {/*PuppyController */}

        <div style={{marginBottom: "1em"}}>
          <NotifyMe puppy={puppy} />
        </div>

        {this.currentPuppy().parent_id === this.props.person.id &&
          this.parentOnlyOptions()}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    person: state.person,
    // loading: state.loading,
    // auth: state.auth
  };
}
export default withRouter(
  connect(mapStateToProps, { addNewHistory, receiveUpdatedActivity, fetchCurrentPersonAction })(Puppy)
);
