import React, {useState} from 'react';

import {PeeIcon, PooIcon, WaterIcon, FoodIcon, SleepIcon, StopIcon, BallIcon} from './Icons';
import AskAccidentPrompt from './AskAccidentPrompt';
import './ActivityButton.scss';

const ActivityButton = (props) => {

	return (
		<div className={`ActivityButton ${props.klass}`}>
			<button onClick={ props.onClickFunc } >
				{props.icon}
			</button>
			<div className='ActivityButton__label'>{props.label}</div>
		</div>
	)
}

const PeeButton = (props) => {
	const [askAccident, setAskAccident] = useState(false);
	return (
		<>
		<AskAccidentPrompt 
			show={askAccident} 
			dismissFunc={ () => setAskAccident(false) } 
			successFunc={ props.successFunc } 
			accidentFunc={ props.accidentFunc } />
		<ActivityButton 
			label="Pee" 
			klass="Pee" 
			icon={<PeeIcon/>} 
			onClickFunc={(e) => setAskAccident(true)} />
		</>
	);
} 

const PooButton = (props) => {
	const [askAccident, setAskAccident] = useState(false);
	return (
		<>
		<AskAccidentPrompt 
			show={askAccident} 
			dismissFunc={ () => setAskAccident(false) } 
			successFunc={ props.successFunc } 
			accidentFunc={ props.accidentFunc } />
		<ActivityButton 
			label="Poo" 
			klass="Poo" 
			icon={<PooIcon/>} 
			onClickFunc={(e) => setAskAccident(true)} />
		</>
	);
} 

const DrinkButton = (props) => {
	return (
		<ActivityButton 
			label="Drink" 
			klass="Drink" 
			icon={<WaterIcon/>} 
			onClickFunc={props.onClickFunc} 
		/>
	);
} 

const EatButton = (props) => {
	return (
		<ActivityButton 
			label="Eat" 
			klass="Eat" 
			icon={<FoodIcon/>} 
			onClickFunc={props.onClickFunc}
		/>
	);
} 

const SleepButton = (props) => {
	return (
		<ActivityButton 
			label="Sleep" 
			klass="Sleep" 
			icon={<SleepIcon />} 
			onClickFunc={props.onClickFunc}
		/>
	);
}

const StopSleepButton = (props) => {
	return (
		<ActivityButton 
			label="Stop Sleep" 
			klass="Sleep" 
			icon={<StopIcon />} 
			onClickFunc={props.onClickFunc}
		/>
	);
}

const ExerciseButton = (props) => {
	return (
		<ActivityButton 
			label="Exercise" 
			klass="Exercise" 
			icon={<BallIcon />} 
			onClickFunc={props.onClickFunc}
		/>
	);
}

const StopExerciseButton = (props) => {
	return (
		<ActivityButton 
			label="Stop Exercise" 
			klass="Exercise" 
			icon={<StopIcon />} 
			onClickFunc={props.onClickFunc}
		/>
	);
}

export  { PeeButton, PooButton, DrinkButton, EatButton, SleepButton, StopSleepButton, ExerciseButton, StopExerciseButton };