import easypuppyApi from './api/easypuppy';
import config from './config';


function requestPersonData() {
  return {
    type: 'REQUEST_PERSON_DATA'
  };
}

function receivePersonData(person) {
  return {
    type: 'RECEIVE_PERSON_DATA',
    person: person
  };
}

function toggleInstallInstructions() {
    return {
        type: 'TOGGLE_INSTALL_INSTRUCTIONS'
    };
}

const fetchCurrentPersonAction = function(){
    // Thunk magically passes the dispatch function to the returned function...
    return (dispatch) => {
        dispatch( requestPersonData() );
        return easypuppyApi.get(config.currentPersonPath)
            .then(
                response => {
                    // console.log('fetchCurrentPersonAction succeded', response)
                    return response.data.person; 
                },
                error => { 
                    // console.log(error); //this will be 401 when logged out
                    throw new Error('fetchCurrentPersonAction failed');
                } 
            )
            .catch(
                error => {
                    console.error('Fetching current person failed', error);
                }
            )
            .then(
                (person) => {
                    dispatch( receivePersonData(person) );
                }
            );
    };
};

const receiveUpdatedExcretionOrIntake = function(excretionOrIntake){
    return {
        type: 'UPDATE_EXCRETION_OR_INTAKE',
        excretionOrIntake: excretionOrIntake
    }
};

const receiveUpdatedActivity = (activity) => ({type: 'UPDATE_ACTIVITY', activity: activity});

const deleteAction = function(action) {
    return {
        type: 'DELETE_ACTION',
        action: action
    }
}

const addNewHistory = (event) => ({type: 'ADD_NEW_HISTORY', event: event});

export { 
    fetchCurrentPersonAction, 
    receivePersonData, 
    toggleInstallInstructions, 
    receiveUpdatedExcretionOrIntake, 
    deleteAction,
    receiveUpdatedActivity,
    addNewHistory
};