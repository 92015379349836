import React from 'react';
import config from '../config';
import easypuppyApi from '../api/easypuppy';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

class SignOut extends React.Component {

  async componentDidMount() {
    this.handleSignout();
  }

  handleSignout = async () => {
    easypuppyApi.delete(config.logoutPath)
      .then( (res) => {
        this.props.dispatch({type: 'SIGN_OUT'});
      })
      .catch( (err) => {
        console.error('SignOut Fail', err);
        console.log('Resetting local data anyway...')
        this.props.dispatch({type: 'SIGN_OUT'});
      });
  }

  render() {
    return <Redirect to='/' />;
  };
};

export default connect()(SignOut);
