import React from 'react';

// const hasValidUrlProtocol = (url = '') => 
//     Boolean(['http://', 'https://', 'ftp://'].some(protocol => url.startsWith(protocol)))

// const staticAssetIfNecessary = (url) =>
// 	 ( hasValidUrlProtocol(url) ) ? url : 'cdvfile://localhost/persistent' + url;

const Avatar = (props) => {
	return <img src={props.puppy.photo.medium_url} className="puppyshot" alt="Puppy headshot" />
}

export default Avatar;