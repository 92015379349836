import React from 'react';
import config from '../config';
import easypuppyApi from '../api/easypuppy';
import {Redirect, Link} from "react-router-dom";
import store from "../store";
import SendButtonText from './SendButtonText';
import queryString from "query-string";
import { connect } from 'react-redux';


class SignUp extends React.Component {

  state = {
    toNewPuppy: false,
    errors: false,
    sending: false
  }

  constructor(props) {
    super(props);
    this.handleSignup = this.handleSignup.bind(this);
    this.renderErrors = this.renderErrors.bind(this);
  }

  componentDidMount() {
    this.setState({toNewPuppy: false, errors: false});
  }

  pendingInvitation = () => {
      const parse = queryString.parse(this.props.location.search);
      return parse.invitation_code || false
  }

  async handleSignup (e) {
      e.preventDefault();

      this.setState({sending: true});

      console.log('handleSignup');
      easypuppyApi
        .post(config.signUpPath, {
          email: document.getElementById("email").value,
          password: document.getElementById("password").value,
          password_confirmation: document.getElementById("password2").value,
          // phone_number: document.getElementById("phone_number").value,
          // text_me: document.getElementById("text_me").checked,
          invitation_code:
            document.getElementById("invitation_code").value || false,
        })
        .then((res) => {
          store.dispatch({
            type: "RECEIVE_PERSON_DATA",
            person: res.data.person,
          });
          store.dispatch({
            type: "RECEIVE_AUTH",
            auth: {
              uid: res.headers.uid,
              provider: "email",
              token: res.headers["access-token"],
              expiry: res.headers.expiry,
              client: res.headers.client,
            },
          });
          console.log("signup response", res);
          if (!res.data.person.puppies.length) {
            this.setState({ toNewPuppy: true });
          }
        })
        .catch((err) => {
          this.setState({ errors: err.response.data.errors });
        })
        .finally(() => {
          this.setState({ sending: false }); //@TODO this needs to move into .then and .catch above to avoid unmoutned component error
        });
  }

  // @TODO This is lame!
  renderErrors(errors) {
    return <React.Fragment>{Object.keys(errors).map( (key, index) => <p key={index}> {errors[key]}</p> )}</React.Fragment>;
  };

  render() {

    if (this.state.toNewPuppy === true) {
      return <Redirect to="/newpuppy" />;
    };

    if (this.props.person && this.props.person.puppies.length) {
      // person registered and had a puppy invite so skipping the /newpuppy
      return <Redirect to="/puppy/0" />;
    }

    return (
      <div>
        <h2>Sign Up</h2>

        {this.state.errors && (
          <div style={{ whiteSpace: "normal" }} className="label alert">
            {this.renderErrors(this.state.errors)}
          </div>
        )}
        <form onSubmit={this.handleSignup}>
          <div className="field">
            <label>Email</label>
            <input id="email" placeholder="email" type="email" />
          </div>

          {/* <div className="field">
              <label>US Phone</label>
              <input id="phone_number" type="tel" placeholder="555 555 5555" />
            </div>

            <div className="field checkbox-w-label">
              <input type="checkbox" name="text_me" id="text_me" defaultChecked />
              <label htmlFor="text_me">Text me when my puppy needs to go out</label>
            </div> */}

          <div className="field">
            <label>Password</label>
            <input id="password" placeholder="password" type="password" />
          </div>

          <div className="field">
            <label>Password confirmation</label>
            <input id="password2" placeholder="" type="password" />
          </div>

          <input
            type="hidden"
            id="invitation_code"
            name="invitation_code"
            value={this.pendingInvitation() || ""}
          />

          <div className="actions">
            <button className="button" disabled={this.state.sending}>
              <SendButtonText text="Sign Up" sending={this.state.sending} />
            </button>
            <Link style={{ marginLeft: "1em" }} to="/signin">
              I already have an account
            </Link>
          </div>
        </form>
      </div>
    );
  };
};

function mapStateToProps(state) {
  return {
    person: state.person,
    loading: state.loading
  };
}

export default connect(mapStateToProps)(SignUp);
