import React from 'react';
import easypuppyApi from '../api/easypuppy.js';
import { connect } from 'react-redux';
import config from '../config';
import { Link } from "react-router-dom";
import {receivePersonData} from '../actions';

class AcceptInvitation extends React.Component {

	constructor(props) {
	    super(props);
		const code = this.props.match.params.code;
	    this.state = {
	      code : code,
	      sending : true,
	      error: false
	    }

	    this.handleAccept();
	}

	handleAccept = () => {
		console.log('Accepting invitation');
		easypuppyApi.acceptInvitation(this.state.code)
			.then(
				(res) => {
					console.log(res)
					receivePersonData(res.data);
				},
				(error) => {
					console.log(error)
					this.setState({error: true});
				}
			)
			.finally(
				() => this.setState({sending: false})
			);
	}

	render() {
		if (this.state.sending) {
			return 'Loading...';
		}
		if (this.state.error) {
			return <p>Something went wrong! Please try again or <a href={config.helpUrl}>contact support</a></p>;
		}
		if (this.props.person) {
			return (
				<>
				<h1>Success!</h1>
				<p>You have accepted the invitation.</p>
			{/* This is crappy because it relies on the order of puppies on the server matching the client-side order */}
				<Link className="button primary" to={`/puppy/0`}>Go to puppy...</Link>
				</>
			);
		} else {
			return (
				<>
				<h1>Success!</h1>
				<p>You have accepted the invitation.</p>
				<Link className="button primary" to='/signin'>Sign In</Link>
				</>
			)
		}
	}
}

function mapStateToProps(state) {
  return {
    person: state.person,
    loading: state.loading,
    auth: state.auth
  };
}

export default connect(mapStateToProps)(AcceptInvitation);