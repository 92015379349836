import React from 'react';
import config from '../config';
import easypuppyApi from '../api/easypuppy';

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      submitSuccess : false,
      sending : false,
      error: false,
      notFound: false
    }
  }

  resetRedirectUrl() {
    // return `${ window.location.protocol }//${ window.location.hostname + (window.location.port ? ':' + window.location.port: '') }/reset`;
    return `https://wagaruffin.com/reset`;
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.setState({sending: true, error: false, notFound: false, submitSuccess: false});

    easypuppyApi.post(config.passwordResetPath, {
        
        email: document.getElementById("email").value,
        redirect_url: this.resetRedirectUrl() //The API will redirect user here w/ new token in query string
    })
    .then( res => this.setState({sending: false, submitSuccess: true}))
    .catch(
      err => { 
        console.log(err);
        let notFound = (err.response.status === 422);
        this.setState({sending: false, error: true, notFound: notFound}); 
      }
    )
  }

  render() {

    // Work like this is why you shouldn't code while really tired or busy...
    return (
        <div>
          <h2>Send Password Reset Link</h2>
          { !this.state.submitSuccess && !this.state.error && <p>Enter your email address and we will send you a link to reset your password.</p> }
          { this.state.submitSuccess && <p className="label success">A reset link was sent to your email address.</p> }
          { this.state.error && this.state.notFound && <p className="label warning">That email address was not found</p> }
          { this.state.error && !this.state.notFound && <p className="label warning">Something went wrong :-(</p> }
          <form onSubmit={this.handleSubmit}>
            <div className="field">
              <label>Email</label>
              <input id="email" placeholder="email" type="email" />
            </div>

            <div className="actions">            
              <button className="button">{ this.state.sending && '...'} {!this.state.sending && 'Send Reset Link'}</button>
            </div>
          </form>
        </div>
      );
  };
};

export default ForgotPassword;
