import React from 'react';
import Puppy from './Puppy';
// import InstallAppButton from './InstallAppButton';
import { connect } from 'react-redux';

class PuppyHOC extends React.Component {

    render() {
        if (this.props.person ) {
        	return(
        		<>
        		<Puppy upstreamStateFunc={this.props.upstreamStateFunc} refreshHackFunc={this.props.refreshHackFunc} />
        		{/*<InstallAppButton />*/}
        		</>
        	);
        }
    }
}

function mapStateToProps(state) {
  return {
    person: state.person,
    // loading: state.loading,
    // auth: state.auth
  };
}

export default connect(mapStateToProps)(PuppyHOC);