import React, {useState, useEffect} from 'react';
import HistoryList from './HistoryList';
import api from '../api/easypuppy.js';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';

import './PuppyHistory.scss';

function PuppyHistory (props) {

    const [loading, setLoading] = useState(true);
    const [puppyHistory, setPuppyHistory] = useState([]);

    const puppy = props.person.puppies[props.match.params.index]

    function goBack(){
        props.history.goBack();
    }

    useEffect(() => {
        api.getPuppyHistory(puppy.id)
        .then( (res) => {
            setPuppyHistory(res.data);
            setLoading(false);
        })

    }, [puppy.id]);

    if (loading) {
        return(
            <div>LOADING...</div>
        );
    }

    return(
        <>
            <button
                className="button secondary" 
                onClick={goBack} 
                style={{
                    position:'fixed',
                    top: '.5em',
                    left: '.5em',
                    zIndex: 1 // prevents the pee icon from floating in front because it's css filter changes it's stacking context
                }}
            ><span aria-hidden>👈</span> Back</button>
            <div className="puppy-header" style={{paddingTop: '1em'}}>
                <h1>{puppy.name} Activity</h1>
            </div>
            <HistoryList histories={puppyHistory} showTimeModeControl={true} startClockTimeMode={true} />
            {/* <button className="button secondary" style={{position: 'sticky', bottom: 0}} onClick={goBack}>&larr; Back</button> */}
        </>
    );

    
}


function mapStateToProps(state) {
  return {
    person: state.person,
    // loading: state.loading,
    // auth: state.auth
  };
}
export default withRouter(connect(mapStateToProps, {})(PuppyHistory));
