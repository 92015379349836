import React from "react";
import { useState } from "react";
import api from '../api/easypuppy';
import "./NotifyMe.scss";
import { fetchCurrentPersonAction } from "../actions";
import { connect } from 'react-redux';

const NotifyMe = ({puppy, dispatch}) => {
    const [fetching, setFetching] = useState(false)

    const handleChange = (e) => {
        setFetching(true)
        api.updateNotify(puppy.id, {
            notify_on: e.target.checked
        })
        .then(
            () => {
                // This is the lazy way to update the state
                dispatch(fetchCurrentPersonAction())
            }
        )
        .finally(
            () => {
                setFetching(false)
            }
        )
    }

    return(
        <div className="notify-me">
            <div>Notifications:</div>
            <div className="switch">
                <input 
                    className="switch-input" 
                    id="exampleSwitch" 
                    type="checkbox" 
                    name="exampleSwitch" 
                    checked={puppy.notifyOn}
                    value="1"
                    onChange={handleChange}
                    disabled={fetching}
                />
                <label className="switch-paddle" htmlFor="exampleSwitch">
                    <span className="show-for-sr">Time to go out notifications</span>
                    <span className="switch-active" aria-hidden="true">On</span>
                    <span className="switch-inactive" aria-hidden="true">Off</span>
                </label>
            </div>
        </div>
    );
}
  
export default connect()(NotifyMe);