import React, {useState} from 'react';
import ActivityEditMode from './ActivityEditMode';
// import {  } from './Icons';
import './ActivityEditButton.scss';

const ActivityEditButton = (props) => {

	const [editMode, setEditMode] = useState(false);

	const handleClick = (e) => {
		setEditMode(true);
	}

	return(
		<>
		{ editMode && <ActivityEditMode closeFunc={ (e) => setEditMode(false) } activity={props.activity} />}
		<button className="ActivityEditButton link-button" onClick={handleClick}>Edit</button>
		</>
	);
}

export default ActivityEditButton;