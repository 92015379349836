import React from 'react';
import config from '../config';
import easypuppyApi from '../api/easypuppy';
import { Redirect } from "react-router-dom";
import SendButtonText from './SendButtonText';
// import store from "../store";
import {fetchCurrentPersonAction} from '../actions';
import { connect } from 'react-redux';

const EditErrors = ({errors}) => {
	return (
		<div class="error_explanation">
    		<strong>{ errors.length } error(s) prevented this update:</strong>
 			<ul>
    			{ errors && errors.full_messages.map( (msg) => <li>{ msg }</li> ) }
    		</ul>
  		</div>
	);
}


class CreatePuppy extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			puppy: {},
			errors: false,
			toPuppy: false,
			sending: false

		}


		this.form = React.createRef();
		this.fileInput = React.createRef();
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({
		  [name]: value
		});
	}

	buildFormDataObj = (event) => {
		const formData = new FormData();

		formData.set('puppy[dob]', this.state.dob);
		formData.set('puppy[name]', this.state.name);
		if (this.fileInput.current.files.length) {
			formData.append('puppy[headshot]',this.fileInput.current.files[0]);
		}

		return formData;
	}

	makeDate = () => {
		const date = new Date();
		return date.toISOString().split('T')[0];
	}

    async handleSubmit (event) {
        event.preventDefault();

        this.setState({sending: true});

        let formData = this.buildFormDataObj();

        try {
            let response = await easypuppyApi.post( 
                config.puppyCreatePath,
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );
            console.log('handleSubmit response', response);

			this.props.dispatch( fetchCurrentPersonAction() ).then( () => this.setState({toPuppy: true, sending: false}) );
            
        } catch (e) {
            console.log(e);
        }

        this.setState({sending: false});
        
    }
	render() {

		if (this.state.toPuppy) {
			return <Redirect to={{pathname: `/puppy/${this.props.person.puppies.length-1}`}} />
		}
		return (

			<React.Fragment>

			<h2>Create a Puppy</h2>

			{ this.state.errors && <EditErrors errors={this.state.errors} />}

			<form ref={this.form} onSubmit={this.handleSubmit}>
			    <label>Name</label>
			    <input type="text" name="name" defaultValue={this.state.puppy.name} onChange={this.handleInputChange} />
			    
			    <label>Puppy Photo</label>
			    <input type='file' name="headshot" ref={this.fileInput} />

			    <label>Birthday</label>
			    <input type="date" name="dob" defaultValue={this.state.puppy.dob || this.makeDate()} onChange={this.handleInputChange} />
			    
			    <button type="submit" className="button expanded" disabled={this.state.sending}>
			    	<SendButtonText text="Save New Puppy" sending={this.state.sending} />
			    </button>
			</form>
			</React.Fragment>
		);
	}
}

function mapStateToProps(state) {
  return {
    person: state.person
  };
}

export default connect(mapStateToProps)(CreatePuppy);