import React from 'react';

class InlineError extends React.Component {

	render() {
		if (this.props.errors && this.props.errors[this.props.name]) {
			return <span className="inline-error" style={{color: 'red'}}>{ this.props.errors[this.props.name].join() }</span>;
		}
		return null;
	}
}

export default InlineError;