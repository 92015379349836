import React from 'react';
import config from '../config';
import easypuppyApi from '../api/easypuppy';
import {Redirect, Link} from "react-router-dom";
import SendButtonText from './SendButtonText';
import { connect } from 'react-redux';
import { fetchCurrentPersonAction } from '../actions';
import queryString from "query-string";

class SignIn extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      toPuppies: false,
      error: false,
      sending: false,
      toInvitationPuppy: false
    }

  }

  componentDidMount() {
    if (this.isInvitationAccepted()) {
      this.props.dispatch(fetchCurrentPersonAction())
      .then( () => this.setState({toInvitationPuppy: true}) )
      .catch( (err) => console.log('fetch failed') )
    }    
  }

  isInvitationAccepted = () => {
      const parse = queryString.parse(this.props.location.search);
      return parse.invitation_accepted || false
  }

  handleSignin = (e) => {
    e.preventDefault();

    this.setState(
      {
        error: false,
        sending: true
      }
    );

    easypuppyApi.post(config.loginPath, {
      email: document.getElementById("email").value,
      password: document.getElementById("password").value
    })
    .then( response => {
      console.log('SignIn Success', response);
      // this.props.upstreamStateFunc({person: response.data});
      return this.props.dispatch(
        {
          type: 'RECEIVE_AUTH', 
          auth: {
            uid: response.data.data.uid,
            provider: response.data.data.provider,
            token: response.headers["access-token"],
            expiry: response.headers.expiry,
            client: response.headers.client
          }
        }
      )
    })
    .then(
    //this is where we fetch person!!!!
      (action) => this.props.dispatch(fetchCurrentPersonAction())
    )
    .then(

      //@TODO this is shit. We should set the toPuppy flag and then have a separate fumnction to determine WHICH puppy
      () => (this.isInvitationAccepted()) ? this.setState({toInvitationPuppy: true, sending: false}) : this.setState({toPuppies: true, sending: false})

    )
    .catch( err => {
      // err could be a failed login OR it could be the failed fetchCurrentPersonAction()...
      // @TODO
      if (err.response) {
        this.setState({error: err.response.data.errors[0], sending: false}); //@TODO Handle multiple errors?
      } else {
        this.setState({error: 'Problem signing in!', sending: false})
      }
      console.log('SignIn Fail', err);
    });
  }

  render() {

    //@TODO These conidtionals are ugly!!!!!!!!

    if (this.props.person.id && this.isInvitationAccepted() && this.state.toInvitationPuppy) {
      return <Redirect to={`/puppy/${this.props.person.puppies.length - 1}`} />
    }

    //User is logged in already, invite was accepted, waiting for person.json before redirect
    // tested
    if (this.props.person.id && this.isInvitationAccepted() && !this.state.toInvitationPuppy) {
      return <span>Loading...</span>;
    }

    // User is logged in already, invite invite was accepted, redirect to the newest puppy
    // tested
    if (this.props.person.id && this.state.toInvitationPuppy) {
      return <Redirect to={`/puppy/${this.props.person.puppies.length - 1}`} />
    }

    // user is logged in, but not dealing with invitation
    // tested
    if (!this.isInvitationAccepted() && (this.state.toPuppies === true || this.props.person.id) ) {
      return <Redirect to="/puppy/0" />;
    }

    return (
        <div>

          { this.isInvitationAccepted() && <div className="label success">Invitation accepted!</div> }

          <h2>Sign In</h2>

          { this.state.error && <div style={{whiteSpace: "normal"}} className="label alert">{this.state.error}</div> }

          <form onSubmit={this.handleSignin}>
            <div className="field">
              <label>Email</label>
              <input id="email" placeholder="email" type="email" />
            </div>

            <div className="field">
              <label>Password</label>
              <input id="password" placeholder="password" type="password" />
            </div>

            <div className="actions">            
              <button type="submit" disabled={this.state.sending} className="button" style={{marginRight: "1em"}}>
                <SendButtonText text="Sign In" sending={this.state.sending} />
              </button>
              <Link to="/signup">I'm new. I need to sign-up</Link>
            </div>
            <Link style={{marginRight: "1em"}} to="/forgot">I forgot my password&hellip;</Link>
          </form>
        </div>
      );
  };
};

function mapStateToProps(state) {
  return {
    person: state.person,
    loading: state.loading
  };
}

export default connect(mapStateToProps)(SignIn);
