import React from 'react';
import moment from 'moment'

const strings = {
	learning: 'Learning\u2026',
	now: 'Now!'
}

// const makeTimeString = (timeStamp) => new Date(timeStamp).toLocaleTimeString()
const makeTimeString = (timeStamp) => moment(timeStamp).fromNow();

//@TODO This is dumb. Make this DRY!
const makePooPrediction = (puppy) => {
	if (!puppy.prediction || !puppy.prediction.next_poo_time) {
		return strings.learning;
	}

	// if (puppy.prediction.next_poo_time_is_past && puppy.prediction.poo_data_is_current_enough) {
	// 	return strings.now;
	// }

	// if (!puppy.prediction.next_poo_time_is_past && puppy.prediction.poo_data_is_current_enough) {
		return makeTimeString(puppy.prediction.next_poo_time);
	// }

	// return 'Learning\u2026';
}

const makePeePrediction = (puppy) => {
	if (!puppy.prediction || !puppy.prediction.next_pee_time) {
		return strings.learning;
	}

	// if (puppy.prediction.next_pee_time_is_past && puppy.prediction.pee_data_is_current_enough) {
	// 	return strings.now;
	// }

	// if (!puppy.prediction.next_pee_time_is_past && puppy.prediction.pee_data_is_current_enough) {
		return makeTimeString(puppy.prediction.next_pee_time);
	// }

	// return 'Learning\u2026';
}

const ExcretionPrediction = (props) => {
	return <strong className="next-time">
        <span>{ props.pee_or_poo === 'poo' ? makePooPrediction(props.puppy) : makePeePrediction(props.puppy)}</span>
    </strong>;
}

export default ExcretionPrediction;