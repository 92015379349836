import React from 'react';

const makeIcon = (klass, emoji) => {
	const className = `${klass.toLowerCase()}-icon`;
	return <span className={className} role="img" aria-label={`${klass.toLowerCase()} icon`}>{emoji}</span>;
}

const PeeIcon = (props) => {
	return makeIcon('Pee', <span role="img" aria-label="pee">&#x1F4A6;</span>);
}
const PooIcon = (props) => {
	return makeIcon('Poo', <span role="img" aria-label="poo">&#x1F4A9;</span>);
}
const ThumbsDownIcon = (props) => {
	return makeIcon('Thumbsdown', <span role="img" aria-label="thumbs down">&#x1F44E;</span>);
}
const ThumbsUpIcon = (props) => {
	return makeIcon('Thumbsup', <span role="img" aria-label="thumbs up">&#x1F44D;</span>);
}
const FoodIcon = (props) => {
	return makeIcon('Food', <span role="img" aria-label="food">&#x1F356;</span>);
}
const WaterIcon = (props) => {
	return makeIcon('Water', <span role="img" aria-label="water">&#x1F6B0;</span>);
}
const HappyIcon = (props) => {
	return makeIcon('Happy', <span role="img" aria-label="happy">&#x1F60A;</span>);
}
const SadIcon = (props) => {
	return makeIcon('Sad', <span role="img" aria-label="sad">&#x1F626;</span>);
}
const NoteIcon = () => makeIcon('Note', <span role="img" aria-label="note">&#x1F4DD;</span>);
const SleepIcon = () => makeIcon('Sleep', <span role="img" aria-label="Sleep">&#127769;</span>); 
const StopIcon = () => makeIcon('Stop', <span role="img" aria-label="Stop">&#x1F6D1;</span>);
const BallIcon = () => makeIcon('Ball', <span role="img" aria-label="Ball">&#x1F3BE;</span>); 

export  {
	PeeIcon, 
	PooIcon, 
	ThumbsUpIcon,
	ThumbsDownIcon, 
	FoodIcon, 
	WaterIcon, 
	HappyIcon, 
	SadIcon, 
	NoteIcon,
	SleepIcon,
	StopIcon,
	BallIcon
};