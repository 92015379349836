import React from 'react';

class SendButtonText extends React.Component {

	render() {
		if (this.props.sending) {
			return <span>&middot;&middot;&middot;</span>;
		} else {
			return <span>{this.props.text}</span>;
		}
	}
}

export default SendButtonText;