import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
// import apiClient from './api/easypuppy';
import { loadState, saveState } from "./localStorage";

// @TODO
document.addEventListener(
  "deviceready",
  function () {
    console.log(cordova.plugins.notification.local.launchDetails); // eslint-disable-line no-undef
  },
  false
);

const initialState = {
  person: false,
  loading: true,
  auth: {
    uid: null,
    provider: null,
    token: null,
    expiry: null,
    client: null,
  },
  showInstallInstructions: false,
  scheduledNotifications: [],
};

const scheduleNotifications = (person) => {
  const notification = cordova.plugins.notification; // eslint-disable-line no-undef
  if (!person.puppies || !notification) {
    return;
  }
  // console.log(
  //   "scheduledIds",
  //   notification.local.getScheduled((a) => console.log(a))
  // );
  const notificationsToAdd = [];
  notification.local.cancelAll();

  person.puppies.map((puppy) => {
    // If puppy notifications are turned off, we're done
    if (!puppy.notifyOn) {
      return;
    }

    if (puppy.prediction) {
      const prediction = puppy.prediction;
      console.log(`prediction for ${puppy.name}`, prediction);

      // pee
      if (prediction.next_pee_time && !prediction.next_pee_time_is_past) {
        const alertId = 7777777777 + puppy.id; // must be number
        const notifyAt = new Date(prediction.next_pee_time); //.getTime() - 1000 * 60 * 15;
        console.log(
          "Scheduling pee notification for " + puppy.name,
          new Date(notifyAt)
        );
        notificationsToAdd.push({
          id: alertId,
          title: `${puppy.name} may need to pee soon.`,
          trigger: { at: new Date(notifyAt) },
        });
      }

      // poo
      if (prediction.next_poo_time && !prediction.next_poo_time_is_past) {
        const alertId = 8888888888 + puppy.id; // must be number
        const notifyAt = new Date(prediction.next_poo_time); //.getTime() - 1000 * 60 * 15;
        console.log(
          "Scheduling poo notification for " + puppy.name,
          new Date(notifyAt)
        );
        notificationsToAdd.push({
          id: alertId,
          title: `${puppy.name} may need to poo soon.`,
          trigger: { at: new Date(notifyAt) },
        });
      }
    }
  });

  notification.local.schedule(notificationsToAdd, () => {
    notification.local.getScheduled((a) =>
      console.log("Scheduled notifications", a)
    );
  });
};

const reducer = function (state = initialState, action) {
  switch (action.type) {
    case "REQUEST_PERSON_DATA":
      return state;
    case "RECEIVE_PERSON_DATA":
      console.log("Receiving person data", action.person);
      // @TODO make this conditional on notifications turned on
      scheduleNotifications(action.person);
      return Object.assign({}, state, { person: action.person });
    case "START_LOADING":
      return Object.assign({}, state, { loading: true });
    case "STOP_LOADING":
      return Object.assign({}, state, { loading: false });
    case "RECEIVE_AUTH":
      return Object.assign({}, state, { auth: action.auth });
    case "SIGN_OUT":
      return Object.assign({}, initialState, { loading: false }); //back to initial
    case "DELETE_PUPPY":
      const id = action.puppy.id;
      const newPuppies = state.person.puppies.filter(
        (puppy) => puppy.id !== id
      );
      let newPerson = state.person;
      newPerson.puppies = newPuppies;
      return Object.assign({}, state, { person: newPerson });
    case "TOGGLE_INSTALL_INSTRUCTIONS":
      return Object.assign({}, state, {
        showInstallInstructions: !state.showInstallInstructions,
      });
    case "UPDATE_EXCRETION_OR_INTAKE":
      // @TODO this is shit!
      const puppiesCopy = state.person.puppies;
      for (let i = 0; i < puppiesCopy.length; i++) {
        if (puppiesCopy[i].id === action.excretionOrIntake.puppy_id) {
          for (let j = 0; j < puppiesCopy[i].history.length; j++) {
            if (
              puppiesCopy[i].history[j].id === action.excretionOrIntake.id &&
              puppiesCopy[i].history[j].klass === action.excretionOrIntake.klass
            ) {
              puppiesCopy[i].history[j] = action.excretionOrIntake;
              const newPerson = Object.assign({}, state.person, {
                puppies: puppiesCopy,
              });
              return Object.assign({}, state, { person: newPerson });
            }
          }
        }
      }
      // fallback... maybe someone else deleted the record already?
      return state;
    case "DELETE_ACTION":
      // @TODO this is ALSO shit!
      const puppiesCopyy = state.person.puppies;
      for (let i = 0; i < puppiesCopyy.length; i++) {
        if (puppiesCopyy[i].id === action.action.puppy_id) {
          for (let j = 0; j < puppiesCopyy[i].history.length; j++) {
            if (
              puppiesCopyy[i].history[j].id === action.action.id &&
              puppiesCopyy[i].history[j].klass === action.action.klass
            ) {
              puppiesCopyy[i].history.splice(j, 1);
              const newPerson = Object.assign({}, state.person, {
                puppies: puppiesCopyy,
              });
              return Object.assign({}, state, { person: newPerson });
            }
          }
        }
      }
      return state;

    case "UPDATE_ACTIVITY":
      const puppiesCopyyy = [...state.person.puppies];
      for (let i = 0; i < puppiesCopyyy.length; i++) {
        if (puppiesCopyyy[i].id === action.activity.puppy_id) {
          for (let j = 0; j < puppiesCopyyy[i].history.length; j++) {
            if (
              puppiesCopyyy[i].history[j].id === action.activity.id &&
              puppiesCopyyy[i].history[j].klass === action.activity.klass
            ) {
              // handle wake up
              if (
                puppiesCopyyy[i].history[j].klass === "Sleep" &&
                !puppiesCopyyy[i].history[j].ended_at &&
                action.activity.ended_at
              ) {
                puppiesCopyyy[i].isSleeping = false;
              }
              // handle play mode off
              if (
                puppiesCopyyy[i].history[j].klass === "Exercise" &&
                !puppiesCopyyy[i].history[j].ended_at &&
                action.activity.ended_at
              ) {
                puppiesCopyyy[i].isExercising = false;
              }
              puppiesCopyyy[i].history[j] = action.activity;
              const newPerson = Object.assign({}, state.person, {
                puppies: puppiesCopyyy,
              });
              return Object.assign({}, state, { person: newPerson });
            }
          }
        }
      }
      // fallback... maybe someone else deleted the record already?
      return state;

    case "ADD_NEW_HISTORY":
      const event = action.event;
      const addHxToPuppies = () =>
        state.person.puppies.map((pup) => {
          if (pup.id === event.puppy_id) {
            pup.history.unshift(event);
          }
          // @TODO these two conditionals are stinky!
          if (event.klass === "Sleep" && !event.ended_at) {
            pup.isSleeping = true;
          }
          if (event.klass === "Exercise" && !event.ended_at) {
            pup.isExercising = true;
          }
          return Object.assign({}, pup);
        });
      return Object.assign({}, state, {
        person: Object.assign({}, state.person, { puppies: addHxToPuppies() }),
      });
    default:
      return state;
  }
};

const persistedState = loadState();

const store = createStore(
  reducer,
  persistedState,
  applyMiddleware(thunkMiddleware)
);

store.subscribe(() => {
  const state = store.getState();
  saveState({
    auth: state.auth,
    person: state.person,
  });
});

export { store as default, reducer, initialState };
