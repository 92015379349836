import React from 'react';

import { PeeIcon, PooIcon, FoodIcon, WaterIcon, SleepIcon, BallIcon } from './Icons';

const chooseIcon = (type) => {
	let icon = null;
	switch(type) {
		case 'Pee':
			icon = <PeeIcon />;
			break;
		case 'Poo':
			icon = <PooIcon />;
			break;
		case 'Water':
			icon = <WaterIcon />;
			break;
		case 'Food':
			icon = <FoodIcon />;
			break;
		case 'Sleep':
			icon = <SleepIcon />;
			break;
		case 'Exercise':
			icon = <BallIcon />;
			break;
		default:
			icon = <span className="unkown-icon">??</span>;
	}
	return icon;
}


const HistoryIcon = (props) => {

	return <span className="history-icon"  role="img" aria-label="history icon">
		{chooseIcon(props.type)}
	</span>;
}

export default HistoryIcon;