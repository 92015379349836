import React, { useState } from 'react';
import SendButton from './SendButton';
import { Redirect } from "react-router-dom";
import easypuppyApi from '../api/easypuppy';
import { connect } from 'react-redux';

const DeletePuppyButton = (props) => {

	const [sending, setSending] = useState(false);
	const [deleted, setDeleted] = useState(false);

	const redirectPath = () => {
		if (props.person.puppies.length) {
			return  `/puppy/0`;
		}
		return `/newpuppy`;
	}

	const handleDelete = (e) => {
		e.preventDefault();
		setSending(true);

		if (!window.confirm('Are you sure? This cannot be undone.')){
			setSending(false);
			return;
		};

		easypuppyApi.deletePuppy(props.puppy.id)
			.then( (res) => {
				props.dispatch({
					type: 'DELETE_PUPPY',
					puppy: props.puppy
				});
				setSending(false);
				setDeleted(true);
			});
	} 

    return (
    	<>
    	{deleted && <Redirect to={redirectPath()} />}
    	<SendButton 
    		className="button alert" 
    		sending={sending} 
    		onClick={ (e) => handleDelete(e, props.puppy.id) }>
    			Delete
    	</SendButton>
    	</>
    );
}

function mapStateToProps(state) {
  return {
    person: state.person,
  };
}

export default connect(mapStateToProps)(DeletePuppyButton);
