import React from 'react';
import config from '../config';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

class Navigation extends React.Component {

	constructor(props) {
	 	super(props);
	 	this.state = {
			active : false
		};
		this.props.history.listen((location, action) => {
			this.setState({active: false});
	    });
	}

	handleClick = (event) => this.toggleActive();

	toggleActive = () => {
		this.setState({active: !this.state.active});
	}

	navItems() {
		if (this.props.person && this.props.person.id) {

			return <React.Fragment>
				{/*<li><a href="#@TODO" onClick={(event) => alert('coming soon') }>Settings</a></li>*/}
				{ this.props.person.puppies && 
					this.props.person.puppies.map( (puppy, index) => { 
						return <li key={`puppyId:${puppy.id}`}><Link to={`/puppy/${index}`}>{puppy.name}</Link></li> 
																	}
												) 
				}
				<li><Link to="/newpuppy">+ New Puppy</Link></li>
				<li><Link to="/settings">Settings</Link></li>
				<li><Link to="/signout">Logout</Link></li>
				<li><a href={config.helpUrl}>Help</a></li>
			</React.Fragment>
		} else {
			return <React.Fragment>
				<li><Link to="/signin">Sign In</Link></li>
				<li><Link to="/signup">Sign Up</Link></li>
				<li><a href={config.helpUrl}>Help</a></li>
			</React.Fragment>
		}
	}

	render () {
		return (
			<React.Fragment>
			<button 
				className={`hamburger hamburger--elastic ${this.state.active ? 'is-active' : ''}`}
				onClick={this.handleClick}
				type="button"
                aria-label="Menu" 
                aria-controls="navigation"
                data-testid="hamburger"
            >
                <span className="hamburger-box">
                    <span className="hamburger-inner"></span>
                </span>
            </button>

            <nav className="navigation" style={{overflow: "scroll"}}>
                <ul className="menu vertical">
                	{ this.navItems() }
                </ul>
            </nav>
            </React.Fragment>
          )
	}
}

function mapStateToProps(state) {
  return {
    person: state.person,
    loading: state.loading
  };
}

export default withRouter(connect(mapStateToProps)(Navigation));
