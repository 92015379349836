import React from "react";

// import easypuppyApi from '../api/easypuppy';
import AcceptInvitation from "./AcceptInvitation";
import PuppyHOC from "./PuppyHOC";
import EditPuppy from "./EditPuppy";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import SignOut from "./SignOut";
import Navigation from "./Navigation";
import CreatePuppy from "./CreatePuppy";
import Settings from "./Settings";
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import PuppyInvitation from "./PuppyInvitation";
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import { connect } from "react-redux";
import { fetchCurrentPersonAction } from "../actions";
import "../scss/foundation.scss";
import "../scss/application.scss";
import PuppyHistory from "./PuppyHistory";

const NotFound = () => {
  return (
    <>
      <h1>Oops!</h1>
      <p>We couldn't find what you're looking for.</p>
      <a href="/">Take me home</a>
    </>
  );
};

class App extends React.Component {
  constructor(props) {
    console.log("HI");
    super(props);
    this.updateStateFromChild = this.updateStateFromChild.bind(this);
  }

  async componentDidMount() {
    this.props.dispatch(fetchCurrentPersonAction()).then(
      (person) => {
        this.props.dispatch({ type: "STOP_LOADING" });
      },
      (err) => {
        this.props.dispatch({ type: "STOP_LOADING" });
      }
    );
    // @TODO check this interval before publishing
    this.timer = setInterval(this.refreshData, 5 * 60 * 1000); // refresh five minutes
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  refreshData = async () => {
    if (this.props.person) {
      console.log("Refreshing data...");
      this.props.dispatch(fetchCurrentPersonAction());
    }
  };

  updateStateFromChild(stateObj) {
    this.props.dispatch(fetchCurrentPersonAction()); //@TODO This is a quick/temporary reractor... shouoldn't need this anymore
  }

  render() {
    if (this.props.loading) {
      return "LOADING...";
    }

    return (
      <Router>
        <div>
          {/* wrapper */}

          <div style={{ textAlign: "right" }} className="header">
            <Navigation />
          </div>

          <Switch>
            <Route
              exact
              path="/newpuppy"
              render={(props) =>
                this.props.person ? (
                  <CreatePuppy person={this.props.person} />
                ) : (
                  <Redirect to="/signin" />
                )
              }
            />
            <Route exact path="/puppy/:index/edit" component={EditPuppy} />
            <Route
              exact
              path="/puppy/:index"
              render={(props) =>
                this.props.person ? (
                  <PuppyHOC
                    upstreamStateFunc={this.updateStateFromChild}
                    refreshHackFunc={this.refreshData}
                  />
                ) : (
                  <Redirect to="/signup" />
                )
              }
            />
            <Route
              exact
              path="/settings"
              render={(props) =>
                this.props.person ? (
                  <Settings
                    person={this.props.person}
                    upstreamStateFunc={this.updateStateFromChild}
                  />
                ) : (
                  <Redirect to="/signin" />
                )
              }
            />
            <Route exact path="/" render={() => <Redirect to="/puppy/0" />} />
            <Route exact path="/signin" component={SignIn} />
            <Route exact path="/signup" component={SignUp} />
            <Route
              exact
              path="/signout"
              render={(props) => (
                <SignOut upstreamStateFunc={this.updateStateFromChild} />
              )}
            />
            <Route path="/forgot" component={ForgotPassword} />
            <Route path="/reset" component={ResetPassword} />
            <Route
              exact
              path="/invitation/accept/:code"
              component={AcceptInvitation}
            />
            <Route
              exact
              path="/puppy/:index/invite"
              component={PuppyInvitation}
            />

            <Route
              exact
              path="/puppy/:index/history"
              render={(props) => <PuppyHistory {...props} />}
            />

            <Route component={NotFound} />
          </Switch>

          {/* wrapper */}
        </div>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  return {
    person: state.person,
    loading: state.loading,
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(App);
